"use strict";

// Component Definition
var KTApp = function () {
    /** @type {object} colors State colors **/
    var settings = {};

    var initTooltip = function (el) {
        var theme = el.data('theme') ? 'tooltip-' + el.data('theme') : '';
        var width = el.data('width') == 'auto' ? 'tooltop-auto-width' : '';
        var trigger = el.data('trigger') ? el.data('trigger') : 'hover';

        $(el).tooltip({
            trigger: trigger,
            template: '<div class="tooltip ' + theme + ' ' + width + '" role="tooltip">\
                <div class="arrow"></div>\
                <div class="tooltip-inner"></div>\
            </div>'
        });
    }

    var initTooltips = function () {
        // init bootstrap tooltips
        $('[data-toggle="tooltip"]').each(function () {
            initTooltip($(this));
        });
    }

    var initPopover = function (el) {
        var skin = el.data('skin') ? 'popover-' + el.data('skin') : '';
        var triggerValue = el.data('trigger') ? el.data('trigger') : 'hover';

        el.popover({
            trigger: triggerValue,
            template: '\
            <div class="popover ' + skin + '" role="tooltip">\
                <div class="arrow"></div>\
                <h3 class="popover-header"></h3>\
                <div class="popover-body"></div>\
            </div>'
        });
    }

    var initPopovers = function () {
        // init bootstrap popover
        $('[data-toggle="popover"]').each(function () {
            initPopover($(this));
        });
    }

    var initFileInput = function () {
        // init bootstrap popover
        $('.custom-file-input').on('change', function () {
            var fileName = $(this).val();
            $(this).next('.custom-file-label').addClass("selected").html(fileName);
        });
    }

    var initScroll = function () {
        $('[data-scroll="true"]').each(function () {
            var el = $(this);

            KTUtil.scrollInit(this, {
                mobileNativeScroll: true,
                handleWindowResize: true,
                rememberPosition: (el.data('remember-position') == 'true' ? true : false)
            });
        });
    }

    var initAlerts = function () {
        // init bootstrap popover
        $('body').on('click', '[data-close=alert]', function () {
            $(this).closest('.alert').hide();
        });
    }

    var initCard = function (el, options) {
        // init card tools
        var el = $(el);
        var card = new KTCard(el[0], options);
    }

    var initCards = function () {
        // init card tools
        $('[data-card="true"]').each(function () {
            var el = $(this);
            var options = {};

            if (el.data('data-card-initialized') !== true) {
                initCard(el, options);
                el.data('data-card-initialized', true);
            }
        });
    }

    var initStickyCard = function () {
        if (typeof Sticky === 'undefined') {
            return;
        }

        var sticky = new Sticky('[data-sticky="true"]');
    }

    var initAbsoluteDropdown = function (context) {
        var dropdownMenu;

        if (!context) {
            return;
        }

        $('body').on('show.bs.dropdown', context, function (e) {
            dropdownMenu = $(e.target).find('.dropdown-menu');
            $('body').append(dropdownMenu.detach());
            dropdownMenu.css('display', 'block');
            dropdownMenu.position({
                'my': 'right top',
                'at': 'right bottom',
                'of': $(e.relatedTarget),
            });
        }).on('hide.bs.dropdown', context, function (e) {
            $(e.target).append(dropdownMenu.detach());
            dropdownMenu.hide();
        });
    }

    var initPopModalUrl = function () {

        var parent = this;
        $('body').off('click', '.pop_modal_url').on('click', '.pop_modal_url', function (e) {
            var element = $(this);
            var asli = $(this).html();
            var url = $(this).attr('data-target');
            var elem_id = $(this).attr('rel-id');
            $(this).html('<i class="icon icon-spinner fa-spin"></i> Loading....');
            e.preventDefault();
            $.get(url, {},
                function (data) {
                    if (data == "") {
                        alert('Module not found');
                    }
                    $(element).html(asli);
                    $('body').find('#' + elem_id).remove();
                    $('body').append(data);
                    $('body').find('#' + elem_id).modal({
                        backdrop: 'static',
                        keyboard: false
                    })
                    KTApp.initComponents();
                    // parent.initComp();
                }, 'html'
            ).fail(function () {
                $(element).html(asli);
                alert('Internal server error');
            });
        });
    }
    var initDatepickerWithRightButtonTrigger =  function () {
        var $datepicker = $('.date-picker'),
            $datepickerBtn = $datepicker.parent('.input-group').find('button');
        $datepicker.datetimepicker({
            defaultDate: new Date(),
            format: 'YYYY-MM-DD',
            sideBySide: false
        });

        $datepickerBtn.on('click', function (evt) {
            $(this).closest('.input-group').find('.datetime-picker').datetimepicker('show');
        });
        /*
          var $datepicker = $('.date-picker'),
              $datepickerBtn = $datepicker.parent('.input-group').find('button');

          $datepicker.datepicker({
              autoclose: true,
              orientation: 'auto',
              showOnFocus: true,
              format: 'yyyy-mm-dd',
              todayHighlight: true
          });

          $datepickerBtn.on('click', function (evt) {
              $(this).closest('.input-group').find('.date-picker').datepicker('show');
          });*/
    }
    var initAjaxForm = function () {

        $('body').off('click', '.delete-confirm').on('click', '.delete-confirm', function (e) {
            e.preventDefault();

            var linkURL = $(this).attr("data-target");

            Swal.fire({
                title: 'Are you sure?',
                text: "You will not be able to recover this data!",
                type: "error",
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {

                    window.location.href = linkURL;
                }
            })

        });


        var submit_label = '';

        // pre-submit callback
        function showRequest(formData, jqForm, options) {
            var queryString = $.param(formData);
            return true;
        }

        // post-submit callback
        function showResponse(data, statusText, xhr, $form) {

            var fa;
            switch (data.msg_type) {
                case 'warning':
                    fa = 'warning';
                    break;
                case 'danger':
                    fa = 'times-circle';
                    break;
                case 'success':
                    fa = 'check';
                    toastr.options = {
                        "closeButton": false,
                        "debug": false,
                        "positionClass": "toast-top-right",
                        "onclick": null,
                        "showDuration": "300",
                        "hideDuration": "1000",
                        "timeOut": "3000",
                        "extendedTimeOut": "1000",
                        "showEasing": "swing",
                        "hideEasing": "linear",
                        "showMethod": "fadeIn",
                        "hideMethod": "fadeOut"
                    };
                    toastr['success'](data.message, "Success");
                    if (data.redirect !== '') {
                        window.location.href = data.redirect;
                    }
                    break;
                default:
                    fa = 'warning';
                    break;
            }
            if (fa !== 'check') {
                toastr['error'](data.message, "Failed");

            }
            $form.find(':submit').prop('disabled', false);
            $form.find(':submit').html(submit_label);

        }

        // post-submit callback
        function showError(data, statusText, xhr, $form) {

            $form.find(':submit').prop('disabled', false);
            $form.find(':submit').html(submit_label);
            alert('Internal server error');
        }

        var options = {
            target: '#output', // target element(s) to be updated with server response
            beforeSubmit: showRequest, // pre-submit callback
            success: showResponse, // post-submit callback
            error: showError,
            dataType: 'json', // 'xml', 'script', or 'json' (expected server response type)
            timeout: 0
        };
        // bind to the form's submit event
        $('.ajax-form').off('submit').on('submit', function () {
            submit_label = $(this).find(':submit').html();
            $(this).find(':submit').prop('disabled', true);
            $(this).find(':submit').html('<b>  ' + save_label + '.</b>');
            toastr.clear();
            $(this).ajaxSubmit(options);
            return false;
        });
    }

    var initAbsoluteDropdowns = function () {
        $('body').on('show.bs.dropdown', function (e) {
            // e.target is always parent (contains toggler and menu)
            var $toggler = $(e.target).find("[data-attach='body']");
            if ($toggler.length === 0) {
                return;
            }
            var $dropdownMenu = $(e.target).find('.dropdown-menu');
            // save detached menu
            var $detachedDropdownMenu = $dropdownMenu.detach();
            // save reference to detached menu inside data of toggler
            $toggler.data('dropdown-menu', $detachedDropdownMenu);

            $('body').append($detachedDropdownMenu);
            $detachedDropdownMenu.css('display', 'block');
            $detachedDropdownMenu.position({
                my: 'right top',
                at: 'right bottom',
                of: $(e.relatedTarget),
            });
        });

        $('body').on('hide.bs.dropdown', function (e) {
            var $toggler = $(e.target).find("[data-attach='body']");
            if ($toggler.length === 0) {
                return;
            }
            // access to reference of detached menu from data of toggler
            var $detachedDropdownMenu = $toggler.data('dropdown-menu');
            // re-append detached menu inside parent
            $(e.target).append($detachedDropdownMenu.detach());
            // hide dropdown
            $detachedDropdownMenu.hide();
        });
    };

    return {
        init: function (settingsArray) {
            if (settingsArray) {
                settings = settingsArray;
            }

            KTApp.initComponents();
        },

        initComponents: function () {
            initScroll();
            initTooltips();
            initPopovers();
            initAlerts();
            initFileInput();
            initCards();
            initStickyCard();
            initAbsoluteDropdowns();
            initPopModalUrl();
            initAjaxForm();
            initDatepickerWithRightButtonTrigger();
        },

        initTooltips: function () {
            initTooltips();
        },

        initTooltip: function (el) {
            initTooltip(el);
        },

        initPopovers: function () {
            initPopovers();
        },

        initPopover: function (el) {
            initPopover(el);
        },

        initCard: function (el, options) {
            initCard(el, options);
        },

        initCards: function () {
            initCards();
        },

        initSticky: function () {
            initSticky();
        },

        initAbsoluteDropdown: function (context) {
            initAbsoluteDropdown(context);
        },

        block: function (target, options) {
            var el = $(target);

            options = $.extend(true, {
                opacity: 0.05,
                overlayColor: '#000000',
                type: '',
                size: '',
                state: 'primary',
                centerX: true,
                centerY: true,
                message: '',
                shadow: true,
                width: 'auto'
            }, options);

            var html;
            var version = options.type ? 'spinner-' + options.type : '';
            var state = options.state ? 'spinner-' + options.state : '';
            var size = options.size ? 'spinner-' + options.size : '';
            var spinner = '<span class="spinner ' + version + ' ' + state + ' ' + size + '"></span';

            if (options.message && options.message.length > 0) {
                var classes = 'blockui ' + (options.shadow === false ? 'blockui' : '');

                html = '<div class="' + classes + '"><span>' + options.message + '</span>' + spinner + '</div>';

                var el = document.createElement('div');

                $('body').prepend(el);
                KTUtil.addClass(el, classes);
                el.innerHTML = html;
                options.width = KTUtil.actualWidth(el) + 10;
                KTUtil.remove(el);

                if (target == 'body') {
                    html = '<div class="' + classes + '" style="margin-left:-' + (options.width / 2) + 'px;"><span>' + options.message + '</span><span>' + spinner + '</span></div>';
                }
            } else {
                html = spinner;
            }

            var params = {
                message: html,
                centerY: options.centerY,
                centerX: options.centerX,
                css: {
                    top: '30%',
                    left: '50%',
                    border: '0',
                    padding: '0',
                    backgroundColor: 'none',
                    width: options.width
                },
                overlayCSS: {
                    backgroundColor: options.overlayColor,
                    opacity: options.opacity,
                    cursor: 'wait',
                    zIndex: (target == 'body' ? 1100 : 10)
                },
                onUnblock: function () {
                    if (el && el[0]) {
                        KTUtil.css(el[0], 'position', '');
                        KTUtil.css(el[0], 'zoom', '');
                    }
                }
            };

            if (target == 'body') {
                params.css.top = '50%';
                $.blockUI(params);
            } else {
                var el = $(target);
                el.block(params);
            }
        },

        unblock: function (target) {
            if (target && target != 'body') {
                $(target).unblock();
            } else {
                $.unblockUI();
            }
        },

        blockPage: function (options) {
            return KTApp.block('body', options);
        },

        unblockPage: function () {
            return KTApp.unblock('body');
        },

        getSettings: function () {
            return settings;
        }
    };
}();

// webpack support
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = KTApp;
}

// Initialize KTApp class on document ready
$(document).ready(function () {
    KTApp.init(KTAppSettings);
});

// CSS3 Transitions only after page load(.page-loading class added to body tag and remove with JS on page load)
window.onload = function () {
    var result = KTUtil.getByTagName('body');
    if (result && result[0]) {
        KTUtil.removeClass(result[0], 'page-loading');
    }
}